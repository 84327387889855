import { EnumToArray } from '../../../utils/enumToArray';

enum Appearance {
  Light = 'light',
  Dark = 'dark',
  Color = 'color',
}

enum Kind {
  Default = 'default',
  Primary = 'primary',
}

export const PTZSwitchConfigList = {
  Appearance: EnumToArray([Appearance]),
  Kind: EnumToArray([Kind]),
};

export const PTZSwitchConfig = {
  Appearance,
  Kind,
};
