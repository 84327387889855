import { Component, Event, EventEmitter, Host, Listen, Prop, h } from '@stencil/core';
import { valueToLowerCaseForDataTestId } from '../../utils/utils';
import { PTZSwitchConfig } from './types/ptz-switch.enums';
import { PTZSwitchTypes } from './types/ptz-switch.types';

@Component({
  tag: 'ptz-switch',
  styleUrl: 'ptz-switch.scss',
  shadow: false
})

export class PTZSwitch {
  /** Cor onde o Switch é aplicado */
  @Prop() appearance?: PTZSwitchTypes.Appearance = PTZSwitchConfig.Appearance.Light

  /** Preenche id padrão do Switch */
  @Prop() inputId?: string

  /** Atributo name padrão do Switch */
  @Prop() name: string

  /** Atributo disabled padrão do Switch */
  @Prop() disabled: boolean

  /** Atributo checked padrão do Switch  */
  @Prop({ mutable: true }) checked: boolean = false

  /** Atributo value padrão do Switch */
  @Prop() value: string

  /** Estado de renderização */
  @Prop() skeleton: boolean = false

  /** Evento para saber se o Switch interno está checked e qual está checked */
  @Event({
    eventName: 'changeSwitch',
    composed: true,
    cancelable: true,
    bubbles: true
  }) changeSwitch: EventEmitter<PTZSwitchTypes.ChangedEvent>

  private _syncCheckedWith(value: boolean) {
    this.checked = value
  }

  @Listen('change')
  _emitCheckedEvent(event) {
    if (this.disabled) {
      return
    }
    this._syncCheckedWith(event.target.checked)
    const value = this.checked
    this.changeSwitch.emit({ checked: value, value: this.value, name: this.name })
  }

  render() {
    return (
      <Host>
        {this.skeleton ? (
          <ptz-skeleton width={48} height={24}></ptz-skeleton>
        ) : (
          <input
            data-testid={`${this.inputId ? valueToLowerCaseForDataTestId(this.inputId) : valueToLowerCaseForDataTestId(this.value)}-switch`}
            type="checkbox"
            role="switch"
            aria-checked={this.checked}
            class={`ptz-switch ptz-switch-${this.appearance}${this.checked ? '-checked' : ''}`}
            name={this.name}
            disabled={this.disabled}
            checked={this.checked}
            id={this.inputId}
            value={this.value}
          />
        )}
      </Host>
    );
  }
}
