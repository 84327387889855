ptz-switch {
  .ptz-switch {
    appearance: none;
    -webkit-appearance: none;
    height: $petz-spacing-md;
    width: $petz-spacing-2xl;
    margin: 0;
    border-radius: $petz-rounded-max;
    position: relative;
    cursor: pointer;
    transition: 0.4s;

    &:after {
      content: " ";
      width: $petz-spacing-3xs * 5;
      height: $petz-spacing-3xs * 5;
      border-radius: $petz-rounded-max;
      display: block;
      margin: 0;
      position: absolute;
      top: $petz-border-md;
      transition: 0.4s;
    }

    &:hover {
      &:before {
        content: " ";
        width: $petz-spacing-3xs * 10;
        height: $petz-spacing-3xs * 10;
        border-radius: $petz-rounded-max;
        background-color: $petz-color-neutral-black;
        opacity: $petz-opacity-xs;
        display: block;
        margin: 0;
        position: absolute;
        bottom: -$petz-spacing-2xs;
      }
    }

    &:not(&:checked) {
      &:after {
        left: .2rem;
      }

      &:hover {
        &:before {
          left: -$petz-spacing-2xs;
        }
      }
    }

    &:checked {
      &:after {
        left: 1.6rem;
      }

      &:hover {
        &:before {
          right: -$petz-spacing-2xs;
        }
      }
    }

    &-light {
      &:not(&:checked) {
        background-color: $petz-color-neutral-dark;

        &[disabled] {
          opacity: $petz-opacity-sm;
        }

        &:after {
          background-color: $petz-color-neutral-white;

          &[disabled] {
            opacity: $petz-opacity-sm;
          }
        }
      }

      &-checked {
        background-color: $petz-color-primary-brand;

        &[disabled] {
          opacity: $petz-opacity-sm;
        }

        &:after {
          background-color: $petz-color-neutral-white;

          &[disabled] {
            opacity: $petz-opacity-sm;
          }
        }
      }
    }

    &-dark {
      &:not(&:checked) {
        background-color: $petz-color-neutral-white;

        &[disabled] {
          opacity: $petz-opacity-sm;
        }

        &:after {
          background-color: $petz-color-neutral-black;

          &[disabled] {
            opacity: $petz-opacity-sm;
          }
        }
      }

      &-checked {
        background-color: $petz-color-primary-light;

        &[disabled] {
          background-color: $petz-color-neutral-white;
          opacity: $petz-opacity-sm;
        }

        &:after {
          background-color: $petz-color-neutral-black;

          &[disabled] {
            opacity: $petz-opacity-sm;
            background-color: $petz-color-neutral-black;
          }
        }
      }
    }

    &-color {
      &:not(&:checked) {
        background-color: $petz-color-neutral-white;

        &[disabled] {
          opacity: $petz-opacity-sm;
        }

        &:after {
          background-color: $petz-color-primary-dark;

          &[disabled] {
            opacity: $petz-opacity-sm;
          }
        }
      }

      &-checked {
        background-color: $petz-color-neutral-black;

        &[disabled] {
          opacity: $petz-opacity-sm;
          background-color: $petz-color-neutral-white;
        }

        &:after {
          background-color: $petz-color-primary-light;

          &[disabled] {
            background-color: $petz-color-primary-dark;
            opacity: $petz-opacity-sm;
          }
        }
      }
    }
  }
}
